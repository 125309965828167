ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.2/lib', '/av')

ZoomMtg.preLoadWasm()
ZoomMtg.prepareWebSDK()
ZoomMtg.i18n.load('en-US')
ZoomMtg.i18n.reload('en-US')

const ZOOM_MEETING_SDK_CLIENT_ID = 'glvcUtrmSF2HjlcADcr3xA'

class App extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search)

    const zoomJwt = params.get('zoomJwt')
    const zoomMeetingNumber = params.get('zoomMeetingNumber')
    const zoomMeetingPassword = params.get('zoomMeetingPassword')
    const leaveUrl = params.get('leaveUrl')

    ZoomMtg.init({
      leaveUrl,
      success: () => {
        ZoomMtg.join({
          signature: zoomJwt,
          sdkKey: ZOOM_MEETING_SDK_CLIENT_ID,
          meetingNumber: zoomMeetingNumber,
          passWord: zoomMeetingPassword,
          userName: 'Patient',
        })
      },
    })
  }

  render() {
    return <div className='App' />
  }
}

export default App
